@import 'variables';
@import 'mixins';

.about-page {
    color: white;

    section:not(:first-child) {
        padding: $space-9xl calc(1/12 * calc(100%));
    }

    .hero {
        position: relative;
        overflow: hidden;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25) ),url('../assets/office-1.jpg');
        background-size: cover;
        background-position: center;


        @include mQ(992px) {
            padding: $step-5 0;
        }

        @include mQ-min(992px) {
            min-height: 90vh;
        }

        .hero-bg {
            background-position: center;
            background-size: cover;
            position: absolute;
            display: block;
            z-index: 1;
            top: 0px;
            left: 0px;
        }

        .content-container {
            position: relative;
            z-index: 10;
            width: 100%;
            display: flex;

            &>div {
                background-color: $blue-60;
            }

            .slides-container {
                overflow: hidden;
                padding: $space-4xl-6xl;

                @include mQ-min(992px) {
                    padding-left: calc(1/12 * calc(100%));
                }

            }

            .content {

                h3{
                    font-size: $step-3;
                }
                p{

                    font-size: $step-1;
                    // line-height: 24px;
                    padding-bottom: $space-s-m 0px;
                }

                @include mQ(992px) {
                    text-align: center;
                    justify-content: center;
                    align-items: center;

                    h3 {
                        font-size: $step-4;
                        margin-bottom: $space-s;
                    }

                    p {
                        font-size: $step-1;
                    }

                }
            }


            .block {
                position: absolute;
                right: 0px;
                top: 0px;
                bottom: 0px;
            }
        }
    }


    .founders {
        color: $black;

        .founders-list {
            display: flex;
            justify-content: space-evenly;

            .paper {
                margin: $step-3 0;

                img {
                    box-shadow:
                        0px 2.767256498336792px 2.2138051986694336px 0px #00000005,
                        0px 6.650102138519287px 5.32008171081543px 0px #00000007,
                        0px 12.521552085876465px 10.017241477966309px 0px #00000009,
                        0px 22.3363094329834px 17.869047164916992px 0px #0000000B,
                        0px 41.777610778808594px 33.422088623046875px 0px #0000000D,
                        0px 100px 80px 0px #00000012;
                    margin-bottom: $space-4xl-6xl;
                    width: 320px;
                    height: 420px;
                    object-fit: cover;

                    @include mQ(768px){
                        width: 90%;
                        height: auto;
                    }
                }

                .head{
                    font-size: $step-1;
                    font-weight: bold;
                    margin-bottom: 0px;
                }
                .info{
                    margin-bottom: $space-4xl-6xl;
                }
                .fields{
                    padding-left: $space-4xl-6xl;
                    border-left: 1px solid $dark-blue;
                    p{
                        color: $dark-blue;
                        
                    }
                }
            }
        }
    }

    .purpose {
        background-color: $blue;
        background-image: url('../assets/watermark-white.svg');
        background-repeat: no-repeat;
        background-position: 100% -100px;
        background-size: 770px 420px;

        @include mQ(992px) {
            background-image: none;
        }

        .card-list {
            display: flex;
            align-items: center;

            @include mQ(1200px) {
                flex-direction: column;
            }

            .card:hover {
                transition: scale 300ms ease-in;
                scale: 1.02;
            }

            .card {
                text-align: center;
                background-color: $blue;
                width: 100%;
                height: 20rem;
                padding: 0 $step-2;
                margin: $space-2xl $space-xl;
                border: none;
                box-shadow:
                    0px 2.767256498336792px 2.2138051986694336px 0px #00000005,
                    0px 6.650102138519287px 5.32008171081543px 0px #00000007,
                    0px 12.521552085876465px 10.017241477966309px 0px #00000009,
                    0px 22.3363094329834px 17.869047164916992px 0px #0000000B,
                    0px 41.777610778808594px 33.422088623046875px 0px #0000000D,
                    0px 100px 80px 0px #00000012;

                h3 {
                    margin-top: $space-xs;
                }

                p {
                    font-size: $step-1;
                    line-height: $space-xl;
                }

                @include mQ(1200px) {
                    padding: $space-5xl $space-3xl;

                    h3 {
                        font-size: $step-4;
                    }

                    p {
                        font-size: $step-2;
                    }
                }

                @include mQ(1300px) {
                    margin: $space-2xl $space-xs;
                }
            }
        }
    }

    .our-strategy {
        .slides {
            display: flex;
            flex-direction: column;
            position: relative;
            overflow: hidden;
            min-height: 70vh;

            @include mQ(992px) {
                min-height: 100vh;
            }

            .slider-item {
                &.active {
                    article {
                        transition: all 1s ease-in;
                        opacity: 1;
                    }
                }

                article {
                    overflow: hidden;
                    position: absolute;
                    opacity: 0;
                    width: 100%;


                    @include mQ(992px) {
                        text-align: center;
                        justify-content: center;
                        align-items: center;
                    }

                    display: flex;
                    padding-top: $space-9xl;

                    @include mQ(992px) {
                        flex-direction: column;
                    }

                    .img-grid {
                        --gap: 15px;
                        --num-cols: 2;
                        --row-height: 260px;
                        margin-right: $space-9xl;
                        display: grid;
                        grid-template-columns: repeat(var(--num-cols), 1fr);
                        grid-auto-rows: var(--row-height);
                        gap: var(--gap);
                        width: 50%;
                        min-width: 320px;
                        min-height: 300px;

                        @include mQ(992px) {
                            width: 100%;
                            min-width: 320px;
                            min-height: 300px;
                            margin-bottom: $space-9xl;
                            margin-right: 0;
                        }

                        img {
                            border-radius: 22px;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        .stretched {
                            grid-column: 2 / 3;
                            grid-row: 1 / 3;
                        }
                    }

                    .description {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        align-items: center;
                        text-align: left;
                        width: 50%;

                        @include mQ(992px) {
                            width: 100%;
                        }

                        h3 {
                            width: 100%;
                            font-size: $step-3;
                        }

                        p {
                            width: 100%;
                            font-size: $step-0;
                        }
                    }
                }
            }

            .indicators {
                position: absolute;
                width: 30%;
                bottom: 0%;
                left: 33%;
                text-align: center;

                @include mQ(1200px) {
                    left: 27%;
                    width: 45%;
                }

                @include mQ(800px) {
                    left: 20%;
                    width: 60%;
                }

                svg {
                    font-size: $step-1;
                    color: $blue-60;

                    :hover {
                        cursor: pointer;
                        scale: 0.9;
                    }

                }

                .fill {
                    color: $blue;
                }
            }
        }
    }

    .quality-policy {
        background-color: $blue;
        background-image: url('../assets/quality-policy-bg.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        text-align: center;

        @include mQ(992px) {
            background-image: none;
        }
        article {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: $space-9xl 0 $space-9xl 0;

            img:hover {
                transition: all 1s ease;
                scale: 1.1;
            }

            img {
                margin: $space-9xl;
                width: 100%;
            }

            p {
                font-size: $step-1;
                width: 80%;
            }

            div {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .clients-and-partners {
        color: $black;
        display: flex;
        justify-content: space-between;

        @include mQ(1200px) {
            flex-direction: column;
        }

        @include mQ(992px) {

            .clients-grid,
            .partners-grid {
                img {
                    width: 100%;
                }
            }
        }

        div {
            width: 100%;

            h3 {
                margin-bottom: $space-9xl;
            }
        }

        .clients {
            width: 50%;
    
            @include mQ(1200px) {
                width: 100%;
            }
    
            .clients-grid {
                --gap: 50px;
                --num-cols: 4;
                --row-height: 65px;
                display: grid;
                grid-template-columns: repeat(var(--num-cols), 1fr);
                grid-auto-rows: var(--row-height);
                gap: var(--gap);
    
                img {
                    height: 100%;
                }
    
                .markan {
                    grid-column: 2 / 4;
                }
            }
        }
        
        .partners {
            margin-bottom: $space-9xl;
            width: 40%;
    
            @include mQ(1200px) {
                width: 60%;
            }
    
            @include mQ(800px) {
                width: 90%;
            }
    
            .partners-grid {
                --gap: 50px;
                --num-cols: 3;
                --row-height: 60px;
                display: grid;
                grid-template-columns: repeat(var(--num-cols), 1fr);
                grid-auto-rows: var(--row-height);
                gap: var(--gap);
                justify-items: left;
    
                img {
                    height: 100%;
                }
    
                .hong-ik,
                .stadia {
                    grid-column: 1 / 3;
                }
            }
        }

        img{
            transition: scale 400ms ease-in-out;
            &:hover{
                scale:1.25
            }
        }
    }
}
